<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__address">
        <img
          class="footer__logo"
          src="@/assets/logo/logo-white.svg"
          alt="logo GS Urbanismo"
        />

        Av. Mauro Ramos, nº 1512 - Centro, Sala 1101, Ed. Cidade Milano<br />
        Florianópolis, SC<br />
        CEP 88020-300
      </div>
      <div class="footer__contact">
        <span class="footer__contact-phone">(48) 3307-3700</span>
        <span class="footer__contact-separator">|</span
        ><span class="footer__contact-phone">(48) 99194-5415</span>
        <a
          class="footer__contact-email"
          href="mailto:contato@gsurbanismo.com.br"
        >
          contato@gsurbanismo.com.br
        </a>
      </div>
      <div class="footer__icons">
        <a
          href="https://www.facebook.com/construtoragonzagasampaio/"
          target="_blank"
          rel="noopener"
          aria-label="Facebook"
        >
          <i class="fab fa-facebook-f fa-lg"></i>
        </a>
        <a
          href="http://instagram.com"
          target="_blank"
          rel="noopener"
          aria-label="Twitter"
        >
          <i class="fab fa-twitter fa-lg"></i>
        </a>
        <a
          href="http://linkedin.com"
          target="_blank"
          rel="noopener"
          aria-label="Linkedin"
        >
          <i class="fab fa-linkedin-in fa-lg"></i>
        </a>
        <a
          href="http://instagram.com"
          target="_blank"
          rel="noopener"
          aria-label="Instagram"
        >
          <i class="fab fa-instagram fa-lg"></i>
        </a>
      </div>
    </div>
    <div class="footer__copyright">
      © 2021 GS Urbanismo
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>

<style>
.footer {
  background-color: var(--main-color);
  color: #fff;
  font-size: 0.8em;
  font-weight: 300;
  line-height: 1.5em;
}

.footer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 4em;
}

.footer__address {
  display: flex;
  padding-bottom: 4em;
}

.footer__logo {
  width: 100px;
  height: 60px;
  padding-right: 2.5em;
}

.footer__contact {
  padding: 0 1em 4em;
}

.footer__contact-phone {
  font-size: 1.3em;
  font-weight: 600;
  padding-bottom: 0.3em;
}

.footer__contact-email {
  display: block;
}

.footer__contact-separator {
  font-weight: 600;
  padding: 0 1em;
}

.footer__icons {
  display: flex;
  width: 150px;
  justify-content: space-between;
  padding-bottom: 4em;
}

.footer__icons > a:hover {
  opacity: 0.4;
}

.footer__copyright {
  background-color: #0b151f;
  font-size: 0.9em;
  text-align: center;
  width: 100%;
  padding: 1.2em 0;
}

@media only screen and (max-width: 1100px) {
  .footer__container {
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 700px) {
  .footer__container {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .footer__address {
    flex-direction: column;
    align-items: center;
    padding: 0 0 3em;
  }

  .footer__logo {
    padding: 0 0 2em;
  }

  .footer__contact-separator {
    display: none;
  }
  .footer__contact-phone {
    display: block;
  }
}
</style>
